import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.checkContentHeight();
  }

  // toggle between showing more and less text
  toggleText(event) {
    const contentElement = document.getElementById(event.params.elementId);
    const showMoreElement = document.getElementById(event.params.containerId).querySelector('.show-more');
    if (!contentElement.classList.contains('line-clamp-[10]')) {
      // reapply line clamp
      contentElement.classList.add('line-clamp-[10]');
      showMoreElement.textContent = 'Show more';
    } else {
      // remove line clamp
      contentElement.classList.remove('line-clamp-[10]');
      showMoreElement.textContent = 'Show less';
    }
  }

  // toggle visibility of show more button
  checkContentHeight() {
    const minLinesShowing = 10;
    // unique features
    const featureElements = document.querySelectorAll('[id^="features-"]');
    featureElements.forEach(element => {
      const contentHeight = element.scrollHeight;
      const clampedHeight = parseFloat(getComputedStyle(element).lineHeight) * minLinesShowing;
      if (contentHeight > clampedHeight) {
        document.getElementById('show-more-container-' + element.id.split('-')[1]).classList.remove('hidden');
      }
    });
    // restrictions
    const restrictionElements = document.querySelectorAll('[id^="restrictions-"]');
    restrictionElements.forEach(element => {
      const contentHeight = element.scrollHeight;
      const clampedHeight = parseFloat(getComputedStyle(element).lineHeight) * minLinesShowing;
      if (contentHeight > clampedHeight) {
        document.getElementById('show-more-container-restrictions-' + element.id.split('-')[1]).classList.remove('hidden');
      }
    });
  }
}
