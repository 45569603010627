import { Controller } from "@hotwired/stimulus";
import { Chart } from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";

export default class Chartjs extends Controller {
  static values = {
    score: Number,
    useThemeColors: { type: Boolean, default: false },
  };

  connect() {
    const element = this.hasCanvasTarget ? this.canvasTarget : this.element;
    const data = this.getChartData();
    const options = this.getChartOptions();

    this.chart = new Chart(element.getContext("2d"), {
      type: "bar",
      data: data,
      options: options,
      plugins: [ChartDataLabels],
    });
  }

  getChartData() {
    return {
      labels: [
        "Participant 1",
        "Participant 2",
        "Participant 3",
        "Participant 4",
        "Participant 5",
        "Participant 6",
      ], // Categories on the y-axis
      datasets: [
        {
          label: "Without Retirement Income",
          data: [12, 18, 56, 76, 11, 100], // Data for the first bar in each category
          backgroundColor: this.getBackgroundColors([12, 18, 56, 76, 11, 100]), // Set dynamic colors based on values
          maxBarThickness: 10,
        },
        {
          label: "With Retirement Income",
          data: [46, 30, 60, 75, 25, 100], // Data for the second bar in each category
          backgroundColor: this.getBackgroundColors([46, 30, 60, 75, 25, 100]), // Set dynamic colors based on values
          maxBarThickness: 10,
        },
      ],
    };
  }

  getBackgroundColors(data) {
    // Function to return colors based on data values
    return data.map((value) => {
      if (value <= 34) {
        return this.useThemeColorsValue
          ? this.getThemeColor("er")
          : "rgb(195, 74, 88)"; // Red for values < 33
      } else if (value > 34 && value < 67) {
        return this.useThemeColorsValue
          ? this.getThemeColor("wa")
          : "rgb(234, 207, 122)"; // Yellow for values between 34 and 65
      } else {
        return this.useThemeColorsValue
          ? this.getThemeColor("su")
          : "rgb(174, 202, 163)"; // Green for values > 65
      }
    });
  }

  getThemeColor(variable) {
    let color;
    if (CSS.supports("background: oklch(0 0 0)")) {
      color = `oklch(${getComputedStyle(document.body).getPropertyValue(
        `--${variable}`
      )})`;
    } else {
      color = getComputedStyle(document.body).getPropertyValue(
        `--fallback-${variable}`
      );
    }
    return color;
  }

  getChartOptions() {
    return {
      indexAxis: "y", // This makes the bar chart horizontal
      maintainAspectRatio: false, // Allows control over canvas size
      layout: {
        padding: {
          right: 150, // Adds extra padding on the right for labels
        },
      },
      plugins: {
        datalabels: {
          align: "end", // Align the label at the end (right side of the bar)
          anchor: "end", // Anchor the label at the end of the bar
          clip: false, // Prevent labels from being cut off
          formatter: function (value, context) {
            // Custom labels based on the dataset
            return context.dataset.label;
          },
          color: "#000", // Set the label color
          font: {
            size: 10,
          },
        },
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          beginAtZero: true, // Bars will start at 0 on the x-axis
          min: 0, // Set the minimum value of the x-axis to 0
          max: 100, // Set the maximum value of the x-axis to 100
          grace: "10%", // Add space at the end of the x-axis to prevent labels from being too close to the edge
          grid: {
            display: false, // Disable grid lines on the x-axis
          },
          ticks: {
            stepSize: 10,
            autoSkip: false,
          },
          title: {
            display: true, // Display the x-axis label
            text: "Retirement Health Score", // Text for the x-axis label
            align: "end", // Align the label to the right
            padding: {
              top: 10, // Adjust the padding to control the position of the label below the axis
            },
            font: {
              size: 12, // Customize font size of the label
            },
          },
        },
        y: {
          border: {
            display: false, // Disable the vertical border line on the y-axis
          },
        },
      },
    };
  }
}
