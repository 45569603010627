import Sortable from 'stimulus-sortable'

export default class extends Sortable {
  static targets = ['item', 'input']

  static values = {
    delimiter: {type: String, default: " "}
  }

  setValues() {
    this.inputTarget.value = this.itemTargets
                                 .map(i => i.dataset.positionalItemValue)
                                 .join(this.delimiterValue);
  }

  connect() {
    super.connect()

    // The sortable.js instance.
    this.sortable

    // Your options
    this.options

    // Your default options
    this.defaultOptions

    this.setValues()
  }

  // You can override the `end` method here.
  onUpdate({ item, newIndex }) {
    this.setValues()
    super.onUpdate({ item, newIndex })
  }

  // You can set default options in this getter for all sortable elements.
  get defaultOptions() {
    return {
      animation: 500,
    }
  }

}