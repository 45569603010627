import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "button",
    "error",
    "finish",
    "popup",
    "spinner",
    "start",
    "textarea",
  ];
  static values = {
    createUrl: String,
    path: String,
  };

  close() {
    this.popupTarget.classList.add("hidden");
    this.startTarget.classList.remove("hidden");
    this.finishTarget.classList.add("hidden");
    this.errorTarget.classList.add("hidden");
    this.textareaTarget.value = "";
  }

  open() {
    this.popupTarget.classList.remove("hidden");
  }

  async submit() {
    if (this.textareaTarget.value == "") {
      this.textareaTarget.classList.add("textarea-error");
      this.textareaTarget.onkeypress = () =>
        this.textareaTarget.classList.remove("textarea-error");
    } else {
      this.textareaTarget.disabled = true;
      this.spinnerTarget.classList.remove("hidden");

      // grab the CSRF token from the meta tag
      const csrfToken = document.querySelector("[name='csrf-token']").content;

      const formData = new FormData();
      formData.append("message", this.textareaTarget.value);
      formData.append("path", this.pathValue);

      try {
        const response = await fetch(this.createUrlValue, {
          method: "POST",
          body: formData,
          headers: {
            "X-CSRF-Token": csrfToken,
          },
        });
        if (response.ok) {
          this.startTarget.classList.add("hidden");
          this.finishTarget.classList.remove("hidden");
          this.textareaTarget.disabled = false;
        } else {
          const json = await response.json();
          this.displayError(json.errors);
        }
      } catch (error) {
        this.displayError();
      } finally {
        this.spinnerTarget.classList.add("hidden");
      }
    }
  }

  displayError(errors = []) {
    this.textareaTarget.disabled = false;
    this.errorTarget.classList.remove("hidden");

    let errorMessage = "Oops.  There was an error submitting feedback.";

    if (errors.length > 0) {
      errorMessage += `<br/><br/>${errors.join("<br/>")}`;
    }

    this.errorTarget.innerHTML = errorMessage;
  }
}
