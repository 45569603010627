import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["recordkeeperContainer"];

  search(event) {
    const inputValue = event.target.value.toLowerCase();
    this.recordkeeperContainerTargets.forEach(recordkeeper => {
      const recordkeeperName = recordkeeper.querySelector(".recordkeeper-name").innerText.toLowerCase();
      const products = recordkeeper.querySelectorAll(".product-name");
      let matchFound = false;

      products.forEach(product => {
        const productName = product.innerText.toLowerCase();
        if (recordkeeperName.includes(inputValue) || productName.includes(inputValue)) {
          matchFound = true;
        }
      });

      // Hides any recordkeepers that don't match the input value
      recordkeeper.classList.toggle('hidden', !matchFound);
      recordkeeper.classList.toggle('md:hidden', !matchFound);
    });
  }
}