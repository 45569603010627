import { Controller } from "@hotwired/stimulus";
import { Chart } from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";

const linesFromSliceToLabelPlugin = {
  id: "linesFromSliceToLabelPlugin",
  afterDraw: (chart) => {
    const ctx = chart.ctx;
    const chartArea = chart.chartArea;
    chart.data.labels.forEach((label, index) => {
      const meta = chart.getDatasetMeta(0);
      const arc = meta.data[index];
      const centerX = (chartArea.left + chartArea.right) / 2;
      const centerY = (chartArea.top + chartArea.bottom) / 2;
      const startAngle = arc.startAngle;
      const endAngle = arc.endAngle;
      const midAngle = (startAngle + endAngle) / 2;
      const dx = Math.cos(midAngle) * (arc.outerRadius + 10);
      const dy = Math.sin(midAngle) * (arc.outerRadius + 10);
      const x = centerX + dx;
      const y = centerY + dy;
      const labelX = arc.tooltipPosition().x;
      const labelY = arc.tooltipPosition().y;

      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, y);
      ctx.lineTo(labelX, labelY);
      ctx.lineWidth = 3;
      ctx.strokeStyle = arc.options.backgroundColor;
      ctx.stroke();
      ctx.restore();
    });
  },
};

export default class Chartjs extends Controller {
  static values = {
    highRisk: Number,
    mediumRisk: Number,
    lowRisk: Number,
    showLabels: { type: Boolean, default: true },
    useThemeColors: { type: Boolean, default: false },
  };

  connect() {
    const element = this.hasCanvasTarget ? this.canvasTarget : this.element;
    const data = this.getChartData();
    const options = this.getChartOptions();

    this.chart = new Chart(element.getContext("2d"), {
      type: "pie",
      data: data,
      options: options,
      plugins: this.showLabelsValue
        ? [ChartDataLabels, linesFromSliceToLabelPlugin]
        : [],
    });
  }

  getChartData() {
    return {
      labels: ["HIGH RISK", "MEDIUM RISK", "LOW RISK"],
      datasets: [
        {
          data: [this.highRiskValue, this.mediumRiskValue, this.lowRiskValue],
          backgroundColor: [
            this.useThemeColorsValue
              ? this.getThemeColor("er")
              : "rgb(195, 74, 88)",
            this.useThemeColorsValue
              ? this.getThemeColor("wa")
              : "rgb(234, 207, 122)",
            this.useThemeColorsValue
              ? this.getThemeColor("su")
              : "rgb(174, 202, 163)",
          ],
          borderWidth: 0,
        },
      ],
    };
  }

  getThemeColor(variable) {
    let color;
    if (CSS.supports("background: oklch(0 0 0)")) {
      color = `oklch(${getComputedStyle(document.body).getPropertyValue(
        `--${variable}`
      )})`;
    } else {
      color = getComputedStyle(document.body).getPropertyValue(
        `--fallback-${variable}`
      );
    }
    return color;
  }

  getChartOptions() {
    return {
      animation: {
        duration: 0,
      },
      devicePixelRatio: 4,
      maintainAspectRatio: false,
      responsive: true,
      layout: {
        padding: this.showLabelsValue ? 36 : 0,
      },
      plugins: {
        datalabels: {
          color: (context) =>
            context.dataset.backgroundColor[context.dataIndex],
          anchor: "end",
          align: "start",
          font: {
            weight: "bold",
            size: 14,
          },
          offset: -40,
          formatter: this.formatPercentage,
        },
        legend: {
          display: false,
        },
        tooltip: {
          enabled: this.showLabelsValue,
        },
      },
    };
  }

  formatPercentage(_value, context) {
    const dataset = context.chart.data.datasets[0];
    const total = dataset.data.reduce((a, b) => a + b, 0);
    const percentages = dataset.data.map((val) =>
      Math.round((val / total) * 100)
    );
    let sum = percentages.reduce((a, b) => a + b, 0);

    // Adjust the last percentage to ensure the total is exactly 100%
    if (sum !== 100) {
      const diff = 100 - sum;
      percentages[percentages.length - 1] += diff;
    }

    return percentages[context.dataIndex] + "%";
  }
}
