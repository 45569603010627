import Chart from 'stimulus-chartjs'

export default class extends Chart {
  static values = {
    data: Object,
    labels: Object,
    title: String,
    print: Boolean,
    animate: Boolean
  }

  connect() {
    super.connect()

    var primaryColor;
    if (CSS.supports("background: oklch(0 0 0)")) {
      primaryColor = `oklch(${getComputedStyle(document.body).getPropertyValue("--p") || "0.7666 0.1354 153.4522"})`;
    } else {
      primaryColor = getComputedStyle(document.body).getPropertyValue("--fallback-p") || "#66cc8a";
    }

    const data = JSON.parse(JSON.stringify(this.dataValue));
    data["datasets"][0]["borderColor"] = primaryColor;
    data["datasets"][0]["backgroundColor"] = "transparent";

    this.chart.data = data;
    this.chart.update();
  }

  get defaultOptions() {
    var labels = this.labelsValue;
    var print = this.printValue;
    var title = this.titleValue;
    var animate = this.animateValue;

    var fontColor;
    if (CSS.supports("background: oklch(0 0 0)")) {
      fontColor = `oklch(${getComputedStyle(document.body).getPropertyValue("--bc") || "0.3552 0.0321 262.9875"})`;
    } else {
      fontColor = getComputedStyle(document.body).getPropertyValue("--fallback-bc") || "#333c4d";
    }

    var numericalRatings = Object.keys(labels);
    var minRating = Math.min(...numericalRatings);
    var maxRating = Math.max(...numericalRatings);

    return {
      animation: animate,
      clip: false, // make sure points and lines at the top of the chart aren't cut off
      devicePixelRatio: (print ? 4 : 1), // render chart in higher resolution when printed to avoid blurry charts
      maintainAspectRatio: false,
      spanGaps: true,
      scales: {
        y: {
          afterFit: function(scaleInstance) {
            // set a fixed width for y axis labels so there isn't shifting when switching between charts
            scaleInstance.width = (print ? 50 : 36);
          },
          grid: {
            display: false
          },
          min: minRating,
          max: maxRating,
          title: {
            display: (print ? true : false),
            text: title,
            font: {
              size: 9
            }
          },
          ticks: {
            color: fontColor,
            callback: function (value) {
              return labels[value];
            },
            font: {
              size: 9
            }
          }
        },
        x: {
          grid: {
            display: false
          },
          ticks: {
            color: fontColor,
            maxTicksLimit: 12,
            font: {
              size: 9
            }
          }
        }
      },
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          displayColors: false,
          callbacks: {
            label: function (tooltipItem) {
              return title + ": " + labels[tooltipItem.raw];
            }
          }
        }
      }
    }
  }
}
