import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["field", "button"];
  static values = {
    every: { type: Boolean, default: true },
  };

  connect() {
    // enables the button when returning to the page
    this.enable();
  }

  enable() {
    let enable = true;

    if (this.everyValue) {
      enable = this.fieldTargets.every(this.isFilled);
    } else {
      enable = this.fieldTargets.some(this.isFilled);
    }

    this.buttonTargets.forEach(function (button) {
      if (enable) {
        button.classList.remove("btn-disabled");
        button.disabled = false;
      } else {
        button.classList.add("btn-disabled");
        button.disabled = true;
      }
    });
  }

  isFilled(field) {
    return (
      (field.type === "checkbox" && field.checked) ||
      (field.type !== "checkbox" && field.value !== "")
    );
  }
}
