import { Controller } from "@hotwired/stimulus";
import tours from "../tours";

export default class extends Controller {
  static values = {
    controller: String,
    action: String,
    logoPath: String
  }

  currentTourStep = 0;
  tour = null;
  tourIsActive = false;
  tourWasHidden = false;

  connect() {
    const tourDefinition = this.getTourDefinition();

    if (tourDefinition) {
      this.createTour(tourDefinition);
      this.createResizeHandler();
    }
  }

  getTourDefinition() {
    const tourKey = this.controllerValue + "." + this.actionValue;
    const tourDefinition = tours[tourKey];

    if (tourDefinition) {
      const tourDefinitionClone = JSON.parse(JSON.stringify(tourDefinition));
      tourDefinitionClone.steps.forEach((step, index) => {
        if (step.element) {
          tourDefinitionClone.steps[index].element = document.querySelector(step.element);
        }
        if (step.title) {
          tourDefinitionClone.steps[index].title = step.title.replace("<LOGO>", "<img src='" + this.logoPathValue + "' class='h-6 mr-2' />");
        }
      });
      if (tourDefinitionClone.dontShowAgain) {
        tourDefinitionClone.dontShowAgainCookie = "introjs-" + tourKey + "-dontShowAgain";
      }
      tourDefinitionClone.buttonClass = "btn btn-sm";
      return tourDefinitionClone;
    } else {
      return null;
    }
  }

  createTour(tourDefinition) {
    this.tour = introJs().setOptions(tourDefinition);
    this.tour.start();
    this.tour.goToStep(this.currentTourStep + 1);

    this.tourIsActive = true;
    this.tourWasHidden = false;

    this.tour.onexit(() => this.tourIsActive = false);

    this.tour.onchange((targetElement) => {
      this.currentTourStep = tourDefinition.steps.findIndex((step) => step.element && step.element == targetElement) || 0;
    })
  }

  createResizeHandler() {
    window.addEventListener("resize", () => {
      // Hide the tour for the "sm" breakpoint since it has different html elements thus rendering the tour as currently
      // defined non-functional.
      if (window.innerWidth <= 768) {
        if (this.tourIsActive) {
          this.tour.exit();
          this.tourWasHidden = true;
        }
      } else if (this.tourWasHidden) {
        // recreate the tour since it was being shown when the user shrunk the window
        this.createTour(this.getTourDefinition());
      }
    });
  }
}
