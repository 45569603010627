import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dependent"];

  connect() {}

  change(event) {
    this.dependentTargets.forEach((dependent) => {
      if (event.target.value === dependent.dataset.visibleWhenValue) {
        dependent.classList.remove("hidden");
      } else {
        dependent.classList.add("hidden");
      }
    });
  }
}
