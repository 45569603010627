import { Controller } from "@hotwired/stimulus"

// For the compare workflow, I had to use 2 Stimulus components (compare bar and compare button) rather than 1 because
// the compare bar and the compare buttons aren't part of the same DOM sub-tree.  As a result, Stimulus eventing was
// needed to communicate between the components.
export default class extends Controller {
  static targets = ["compareButton", "count", "input", "placeholderTemplate", "productTemplate", "tile"];
  maxToCompare = 3
  toCompare = []

  connect() {
    // Work around template nodes not being updated by the idiomorph library. Without this fix, clicking the compare
    // button on a product added via the Add Other Products workflow will show the wrong product in the compare bar.
    // See: https://github.com/hotwired/turbo/issues/1087#issuecomment-2051982922
    addEventListener("turbo:morph-element", ({ target, detail: { newElement } }) => {
      if (target instanceof HTMLTemplateElement && newElement instanceof HTMLTemplateElement) {
        target.innerHTML = newElement.innerHTML
      }
    })
  }

  // called when the user clicks the "X" to remove a product from the compare bar
  remove(event) {
    const id = event.currentTarget.getAttribute("data-product-id")
    const index = this.toCompare.indexOf(id)
    this.toCompare.splice(index, 1)
    this.redraw()

    // notify the compare button of the product card that the product has been removed from the comparison
    this.dispatch("remove", {
      detail: {
        id: id
      }
    })
  }

  // called when the compare button of a product is clicked
  toggle(event) {
    const id = event.detail.id;
    const index = this.toCompare.indexOf(id);
    if (index > -1) {
      this.toCompare.splice(index, 1)
      this.redraw();
    } else if (this.toCompare.length < this.maxToCompare) {
      this.toCompare.push(id);
      this.redraw();
    } else {
      // the product wasn't added to the comparison since there are already 3 products being compared.  Cancel the
      // event so the button can avoid redrawing itself.
      event.preventDefault();
    }
  }

  redraw() {
    for (let i = 0; i < this.maxToCompare; i++) {
      if (typeof this.toCompare[i] === "undefined") {
        this.tileTargets[i].replaceChildren(this.placeholderTemplateTarget.content.cloneNode(true))
        this.tileTargets[i].innerHTML = this.tileTargets[i].innerHTML.replace("X", (i + 1))
      } else {
        const template = this.productTemplateTargets.find((template) => {
          return template.getAttribute("data-product-id") == this.toCompare[i]
        });
        this.tileTargets[i].replaceChildren(template.content.cloneNode(true))
      }
      this.inputTargets[i].value = this.toCompare[i] || ""
    }
    if (this.toCompare.length >= 2) {
      this.compareButtonTarget.classList.remove("btn-disabled")
    } else {
      this.compareButtonTarget.classList.add("btn-disabled")
    }

    if (this.toCompare.length >= 1) {
      this.element.style.display = "";
    } else {
      this.element.style.display = "none";
    }

    this.countTarget.innerText = this.toCompare.length;
  }
}
