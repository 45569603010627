import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["ageInput", "balanceInput", "salaryInput", "groupCheckbox", "nextButton"];

  connect() {
    // enables the next button when returning to the demographics page
    this.enableNext();
  }

  enableNext() {
    let enable = true;

    const ageInputProvided = this.getRadioValue(this.ageInputTargets) !== null;
    const balanceInputProvided = this.getRadioValue(this.balanceInputTargets) !== null;
    const salaryInputProvided = this.getRadioValue(this.salaryInputTargets) !== null;

    if (!ageInputProvided || !balanceInputProvided || !salaryInputProvided) {
      enable = false;
    }

    let atleastOneChecked = false;
    this.groupCheckboxTargets.forEach(function (checkbox) {
      if (checkbox.checked) {
        atleastOneChecked = true;
      }
    });
    if (!atleastOneChecked) {
      enable = false;
    }

    let classList = this.nextButtonTarget.classList;
    if (enable) {
      classList.remove("btn-disabled");
    } else {
      classList.add("btn-disabled");
    }
  }

  getRadioValue(inputs) {
    let value = null;
    inputs.forEach(function (input) {
      if (input.checked) {
        value = input.value;
      }
    });

    return value;
  }

  handleNextClick(event) {
    const modal = document.querySelector("#guardrails-modal");

    const guardrails = {
      "median_participant_age": [
        {
          value: "25-34",
          selector: "#median_age_low"
        }
      ],
      "median_participant_balance": [
        {
          value: "< $25K",
          selector: "#median_balance_low"
        }
      ],
      "median_participant_salary": [
        {
          value: "< $25K",
          selector: "#median_salary_low"
        },
        {
          value: "$150K+",
          selector: "#median_salary_high"
        }
      ]
    }

    const showGuardrails = function (field, value) {
      (guardrails[field] || []).forEach(function (guardrail) {
        const guardrailElementClassList = document.querySelector(guardrail.selector).classList

        if (guardrail.value === value) {
          event.preventDefault()
          guardrailElementClassList.remove("hidden")
          modal.classList.add("modal-open")
        } else {
          guardrailElementClassList.add("hidden")
        }
      })
    }

    showGuardrails("median_participant_age", this.getRadioValue(this.ageInputTargets))
    showGuardrails("median_participant_balance", this.getRadioValue(this.balanceInputTargets))
    showGuardrails("median_participant_salary", this.getRadioValue(this.salaryInputTargets))
  }
}
