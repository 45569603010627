import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["hide", "spinner"];

  start() {
    this.hideTargets.forEach((hide) => hide.classList.add("hidden"));
    this.spinnerTarget.classList.remove("hidden");
  }
}
