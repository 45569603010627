import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["plus", "check"];

  // the compare bar will trigger this if the user clicks the "X" to remove a product from the compare bar
  remove(event) {
    if (event.detail.id == this.element.getAttribute("data-product-id")) {
      this.swapIndicators();
    }
  }

  // called when the user clicks on the compare button of a product
  toggle(event) {
    event.preventDefault();
    const productButton = this.element;

    // notify the compare bar that the user wants to add this product to the comparison
    const customEvent = this.dispatch("toggle", {
      detail: {
        id: productButton.getAttribute("data-product-id"),
      },
    });

    // the compare bar will cancel the event if there are already three products being compared
    if (!customEvent.defaultPrevented) {
      this.swapIndicators();
    }
  }

  swapIndicators() {
    this.plusTarget.classList.toggle("hidden");
    this.checkTarget.classList.toggle("hidden");
  }
}
