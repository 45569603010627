import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["hideCheckbox"];
  static values = {
    acknowledgeUrl: String,
    hidden: Boolean
  }

  connect() {
    if (!this.hiddenValue) {
      this.element.classList.add("modal-open")
    }
  }

  acknowledge() {
    // grab the CSRF token from the meta tag
    const csrfToken = document.querySelector("[name='csrf-token']").content

    const formData = new FormData();
    formData.append("hide_disclosures", this.hideCheckboxTarget.checked)

    fetch(this.acknowledgeUrlValue, {
      method: "PUT",
      body: formData,
      headers: {
        "X-CSRF-Token": csrfToken
      }
    }).then(function() {
      this.element.classList.remove("modal-open");
    }.bind(this));
  }
}
