import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "advanced",
    "currentAge",
    "currentAgeLabel",
    "retirementAge",
    "retirementAgeLabel",
    "joint",
    "expectedReturn",
    "fees",
  ];

  checkCurrent() {
    if (this.getRetirementAge() < this.getCurrentAge()) {
      this.retirementAgeTarget.value = this.getCurrentAge() + 1;
    }
    this.updateAgeLabels();
  }

  checkRetirement() {
    if (this.getRetirementAge() < this.getCurrentAge()) {
      this.retirementAgeTarget.value = this.getCurrentAge();
    }
    this.updateAgeLabels();
  }

  getCurrentAge() {
    return parseInt(this.currentAgeTarget.value);
  }

  getRetirementAge() {
    return parseInt(this.retirementAgeTarget.value);
  }

  updateAgeLabels() {
    this.currentAgeLabelTarget.innerText = this.getCurrentAge();
    this.retirementAgeLabelTarget.innerText = this.getRetirementAge();
  }

  toggleAdvanced() {
    this.advancedTarget.classList.toggle("hidden");
    this.jointTarget.checked = false;
    this.expectedReturnTarget.value = "";
    this.feesTarget.checked = false;
  }
}
