import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab", "content"];
  static values = {
    activeClass: { type: String, default: "tab-active" }
  }

  select(event) {
    var selectedIndex = this.tabTargets.indexOf(event.target);
    this.tabTargets.forEach(function(tab, index) {
      if (index === selectedIndex) {
        tab.classList.add(this.activeClassValue);
      } else {
        tab.classList.remove(this.activeClassValue);
      }
    }.bind(this))
    this.contentTargets.forEach(function(content, index) {
      if (index % 3 === selectedIndex) {
        content.classList.remove("hidden");
      } else {
        content.classList.add("hidden");
      }
    });
  }
}
