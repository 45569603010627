import { Controller } from "@hotwired/stimulus"
import { formatHex, interpolate, toGamut, wcagContrast } from "culori"

export default class extends Controller {
  static values = {
    theme: Object
  }

  definedColors = ["primary", "secondary", "accent", "neutral", "base100", "info", "success", "warning", "error"]
  variableNames = {
    primary: "p",
    primaryContent: "pc",
    secondary: "s",
    secondaryContent: "sc",
    accent: "a",
    accentContent: "ac",
    neutral: "n",
    neutralContent: "nc",
    base100: "b1",
    base200: "b2",
    base300: "b3",
    baseContent: "bc",
    info: "in",
    infoContent: "inc",
    success: "su",
    successContent: "suc",
    warning: "wa",
    warningContent: "wac",
    error: "er",
    errorContent: "erc"
  }

  connect() {
    if (!!Object.keys(this.themeValue).length) {
      if (CSS.supports("background: oklch(0 0 0)")) {
        this.setOklchValues();
      } else {
        this.setFallbackHexValues();
      }
    }
  }

  setOklchValues() {
    this.definedColors.forEach((color) => {
      this.element.style.setProperty(
        `--${this.variableNames[color]}`,
        this.colorObjToString(toGamut("oklch")(this.themeValue[color]))
      );
    });
    this.generateDerivedColors().forEach((color) => {
      this.element.style.setProperty(
        color.variable,
        this.colorObjToString(color.value)
      );
    });
  }

  setFallbackHexValues() {
    this.definedColors.forEach((color) => {
      this.element.style.setProperty(
        `--fallback-${this.variableNames[color]}`,
        this.themeValue[color]
      );
    });
    this.generateDerivedColors().forEach((color) => {
      this.element.style.setProperty(
        color.variable.replace("--", "--fallback-"),
        formatHex(color.value)
      );
    });
  }

  colorObjToString(input) {
    const cut = (number) => {
      if (!number) {
        return 0;
      }
      return +number.toFixed(4);
    }
    const { l, c, h } = input;
    return `${cut(l)} ${cut(c)} ${cut(h)}`;
  }

  generateDerivedColors() {
    let derivedColors = [];
    derivedColors.push(this.darken("--b2", "base100", 0.1));     // base-200
    derivedColors.push(this.darken("--b3", "base100", 0.2));     // base-300
    derivedColors.push(this.contrastMaker("--bc", "base100"));   // base-content

    derivedColors.push(this.contrastMaker("--pc", "primary"));   // primary-content
    derivedColors.push(this.contrastMaker("--sc", "secondary")); // secondary-content
    derivedColors.push(this.contrastMaker("--ac", "accent"));    // accent-content
    derivedColors.push(this.contrastMaker("--nc", "neutral"));   // neutral-content

    derivedColors.push(this.contrastMaker("--inc", "info"));     // info-content
    derivedColors.push(this.contrastMaker("--suc", "success"));  // success-content
    derivedColors.push(this.contrastMaker("--wac", "warning"));  // warning-content
    derivedColors.push(this.contrastMaker("--erc", "error"));    // error-content
    return derivedColors;
  }

  darken(variable, source, percentage = 0.2) {
    return {
      variable: variable,
      value: interpolate([this.themeValue[source], "black"], "oklch")(percentage)
    };
  }

  contrastMaker(variable, source) {
    const input = this.themeValue[source];
    const percentage = 0.8;
    return {
      variable: variable,
      value: interpolate([input, this.isDark(input) ? "white" : "black"], "oklch")(percentage)
    };
  }

  isDark(color) {
    return wcagContrast(color, "black") < wcagContrast(color, "white")
  }
}
