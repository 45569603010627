import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  dismiss() {
    // grab the CSRF token from the meta tag
    const csrfToken = document.querySelector("[name='csrf-token']").content
    fetch(this.data.get("dismissUrl"), {
      method: "PUT",
      headers: {
        "X-CSRF-Token": csrfToken
      }
    }).then(function() {
      this.element.classList.add("hidden");
    }.bind(this));
  }
}
