import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["overlay", "sidebar"];

  connect() {
  }

  close() {
    this.sidebarTarget.classList.add("translate-x-full")
    this.sidebarTarget.classList.remove("translate-x-0")
    this.overlayTarget.style.display = "none";
  }

  open() {
    this.sidebarTarget.classList.add("translate-x-0")
    this.sidebarTarget.classList.remove("translate-x-full")
    this.overlayTarget.style.display = "";
  }
}
