import { Controller } from "@hotwired/stimulus";
import { Chart } from "chart.js/auto";

export default class Chartjs extends Controller {
  static values = {
    score: Number,
    useThemeColors: { type: Boolean, default: false },
  };

  connect() {
    const element = this.hasCanvasTarget ? this.canvasTarget : this.element;
    const data = this.getChartData();
    const options = this.getChartOptions();

    this.chart = new Chart(element.getContext("2d"), {
      type: "doughnut",
      data: data,
      options: options,
      plugins: this.getPlugins(),
    });
  }

  getChartData() {
    return {
      datasets: [
        {
          data: [this.scoreValue, 100 - this.scoreValue],
          backgroundColor: [this.getColor(), "transparent"],
          borderWidth: 0,
          borderRadius: 2,
        },
      ],
    };
  }

  getColor() {
    if (this.scoreValue < 50) {
      return this.useThemeColorsValue
        ? this.getThemeColor("er")
        : "rgb(195, 74, 88)";
    } else if (this.scoreValue >= 50 && this.scoreValue < 80) {
      return this.useThemeColorsValue
        ? this.getThemeColor("wa")
        : "rgb(234, 207, 122)";
    } else {
      return this.useThemeColorsValue
        ? this.getThemeColor("su")
        : "rgb(174, 202, 163)";
    }
  }

  getThemeColor(variable) {
    let color;
    if (CSS.supports("background: oklch(0 0 0)")) {
      color = `oklch(${getComputedStyle(document.body).getPropertyValue(
        `--${variable}`
      )})`;
    } else {
      color = getComputedStyle(document.body).getPropertyValue(
        `--fallback-${variable}`
      );
    }
    return color;
  }

  getChartOptions() {
    return {
      animation: {
        duration: 4000,
      },
      cutout: "84%", // Adjust this value to control the size of the center cutout
      plugins: {
        tooltip: { enabled: false },
        legend: { display: false },
      },
    };
  }

  getPlugins() {
    return [
      {
        beforeDraw: (chart) => {
          var width = chart.width,
            height = chart.height,
            ctx = chart.ctx;
          ctx.restore();

          // Draw the circle behind the text
          var centerX = width / 2;
          var centerY = height / 2;
          var radius = Math.min(width, height) / 2.5; // Adjust the size of the circle

          ctx.beginPath();
          ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
          ctx.fillStyle = this.getColor(); // Set the circle color
          ctx.fill();
          ctx.closePath();

          // Draw the text on top of the green circle
          var fontSize = (height / 35).toFixed(2);
          ctx.font = "100 " + fontSize + "em Arial, sans-serif";
          ctx.textBaseline = "middle";
          ctx.fillStyle = "#ffffff"; // Set text color to white to stand out from the background
          var text = this.scoreValue,
            textX = Math.round((width - ctx.measureText(text).width) / 2),
            textY = height / 2;
          ctx.fillText(text, textX, textY);
          ctx.save();
        },
      },
    ];
  }
}
