import { Controller } from "@hotwired/stimulus"

// This controller is used to copy 2fa recovery codes to the clipboard
export default class extends Controller {
  static targets = ["button", "source"]
  static values = {
    successContent: String,
    successDuration: {
      type: Number,
      default: 2000
    }
  }

  connect() {
    this.originalContent = this.buttonTarget.innerHTML
  }

  copy(event) {
    event.preventDefault()

    const text = this.sourceTarget.innerText

    navigator.clipboard.writeText(text).then(function() {
      this.copied()
    }.bind(this));
  }

  copied() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    this.buttonTarget.innerHTML = this.successContentValue

    this.timeout = setTimeout(function() {
      this.buttonTarget.innerHTML = this.originalContent
    }.bind(this), this.successDurationValue)
  }
}
