// Entry point for the build script in your package.json
// Note this file is normally called application.js, but it was renamed to
// workaround this issue: https://github.com/rails/jsbundling-rails/issues/45
import "@hotwired/turbo-rails"

import Alpine from "alpinejs"

window.Alpine = Alpine
Alpine.start()

import "./controllers"
