import { Controller } from "@hotwired/stimulus"

// This is used to trigger click events on elements (ex. button) when
// other events such as a change event on a select box occur
export default class extends Controller {
  static targets = [ "click" ]

  click() {
    this.clickTargets.forEach(function(target) { target.click() });
  }
}
