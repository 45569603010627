import { Controller } from "@hotwired/stimulus"
import { leave } from "el-transition";

export default class extends Controller {
  static targets = ["hide"];
  static values = {
    delay: { type: Number, default: 5000 }
  }

  connect() {
    setTimeout(this.hide.bind(this), this.delayValue)
  }

  hide() {
    leave(this.hideTarget).then(function() {
      this.hideTarget.classList.add("hidden");
    }.bind(this));
  }
}
