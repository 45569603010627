// See https://introjs.com/docs/intro/options and https://introjs.com/docs/examples/basic/json-config for details on
// the data structure below.

export default {
  "dashboard.show": {
    disableInteraction: true,
    dontShowAgain: true,
    steps: [
      {
        title: "<div class='flex items-center'><LOGO> Welcome to Nestimate!</div>",
        intro: "Please take this quick tour to familiarize yourself with the Nestimate dashboard."
      },
      {
        element: "#dashboard-link",
        intro: "You can return to this home page at any time by clicking Dashboard."
      },
      {
        element: "#proposals-link",
        intro: "You can see all of your Lifetime Income Proposals here."
      },
      {
        element: "#products-link",
        intro: "See all of the Lifetime Income Products on the Nestimate platform. New products will be added periodically, and you'll be notified when they are."
      },
      {
        element: "#recordkeeper-adoption-link",
        intro: "See the recordkeepers on which products are currently available."
      },
      {
        element: "#profile-picture",
        intro: "Click here to change your settings or to log out of Nestimate."
      },
      {
        element: "#notifications",
        intro: "This part of the dashboard will be used to display important notices.  These include product updates as well as recordkeeper availability changes."
      },
      {
        element: "#recent-proposals-table",
        intro: "See your most recent proposals here."
      },
      {
        element: "#new-proposal-btn",
        intro: "Let's get started!  Click this button to create your first Lifetime Income Proposal.",
        position: "left"
      }
    ]
  }
}
