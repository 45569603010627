import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button", "checkbox", "checkIcon"];

  enable() {
    this.checkboxTargets.forEach(function(checkbox) {
      checkbox.disabled = false;
    });
    this.checkIconTarget.classList.remove("hidden");
  }
}
